#tag-cloud {
  .tag-cloud-title {
    color: $color-meta;
  }
  .tag-cloud-tags {
    clear: both;
    text-align: center;
    a {
      display: inline-block;
      margin: 10px;
    }
  }
}