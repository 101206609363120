// Fonts
$font-family-body: "JetBrains Mono", monospace;
$font-family-mono: "JetBrains Mono", monospace;
$font-family-tt: "Inconsolata", monospace;
$font-size: 14px;
$line-height: 1.725;
$page-width: 48rem;
// Logo
$logo-width: 50px;
$logo-height: 50px;
$logo-grayout: true;
// Colors
$colors: "dark" // white dark light classic